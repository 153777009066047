// Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  server: {
    apiURL: 'https://lseg-development-api.fairsupply.net/api/v1',
    rssURL: 'https://lseg-development-api.fairsupply.net/rss-feed',
  },
  auth: {
    audience: 'https://api.fairsupplyanalytics-lseg-dev.com',
    clientId: 'tMohMFBV9WcmgL4xEogqPkwHv50Th80y',
    domain: 'fairsupplyanalytics-lseg-dev.au.auth0.com',
    namespace: 'https://fairsupplyanalytics-lseg-dev.com/roles',
  },
  defaultPageTitle: 'FairSupply - LSEG Dev',
  analytics: {
    ga: {
      // Disable google analytics for LSEG dev environment. The following id is for the dev environment
      enabled: false,
      id: 'G-NG1H0D0NK1',
    },
    gtm: {
      // Disable google analytics for LSEG dev environment. The following id is for the dev environment
      enabled: false,
      id: 'GTM-MQ39DKR',
    },
    segment: {
      // Disable segment integration for LSEG dev environment. ATM no segment API has been created for LSEG; and LSEG does not use the frontend.
      enabled: false,
      id: '',
    },
    pf: {
      enabled: false,
      id: '',
    },
    app: 'FairSupply',
  },
  productFruits: {
    helpDeskURL: '',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
